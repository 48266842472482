import request from '@/utils/request'
// 获取小区(组)列表
export function GetRegionPage (params) {
  return request({
    url: 'Region/GetRegionPage',
    method: 'get',
    params
  })
}
// 获取楼栋列表
export function GetBuildPage (params) {
  return request({
    url: 'Build/GetBuildPage',
    method: 'get',
    params
  })
}
// 获取单元列表
export function GetBuildUnitPage (params) {
  return request({
    url: 'Build/GetBuildUnitPage',
    method: 'get',
    params
  })
}
// 获取房屋列表
export function GetResidentPage (data) {
  return request({
    url: 'Resident/GetResidentPage',
    method: 'post',
    data
  })
}
// 获取住户列表
export function GetResidentListByFamily (params) {
  return request({
    url: 'Resident/GetResidentListByFamily',
    method: 'get',
    params
  })
}
// 获取住户列表
export function GetBuildRoomPage (params) {
  return request({
    url: 'Build/GetBuildRoomPage',
    method: 'get',
    params
  })
}
// 获取住户列表
export function GetBuildFamilyPage (params) {
  return request({
    url: 'Build/GetBuildFamilyPage',
    method: 'get',
    params
  })
}
// 
export function GetBuildFamilyList (params) {
  return request({
    url: 'Build/GetBuildFamilyList',
    method: 'get',
    params
  })
}
// 获取住户详情
export function GetResident (params) {
  return request({
    url: 'Resident/GetResident',
    method: 'get',
    params
  })
}
// 获取字典别表
export function GetCodeEntrysList (params) {
  return request({
    url: 'Code/GetCodeEntrysList',
    method: 'get',
    params
  })
}
// 保存指定家庭的住户信息
export function SaveResidentOfFamily (data) {
  return request({
    url: 'Resident/SaveResidentOfFamily',
    method: 'post',
    data
  })
}
// 获取小区(组)别表
export function GetRegionInfo (params) {
  return request({
    url: 'Region/GetRegionInfo',
    method: 'get',
    params
  })
}
// 保存小区(组)信息
export function SaveRegion (data) {
  return request({
    url: 'Region/SaveRegion',
    method: 'post',
    data
  })
}
//获取小区(组)列表(下拉菜单)
export function WeGetRegionList (params) {
  return request({
    url: 'Region/WeGetRegionList',
    method: 'get',
    params
  })
}
//获取小区(组)列表(下拉菜单)
export function GetRegionList (params) {
  return request({
    url: 'Region/GetRegionList',
    method: 'get',
    params
  })
}
//获取楼栋列表(下拉菜单)
export function WeGetBuildList (params) {
  return request({
    url: 'Build/WeGetBuildList',
    method: 'get',
    params
  })
}
export function GetBuildList (params) {
  return request({
    url: 'Build/GetBuildList',
    method: 'get',
    params
  })
}
//获取单元列表(下拉菜单)
export function WeGetBuildUnitList (params) {
  return request({
    url: 'Build/WeGetBuildUnitList',
    method: 'get',
    params
  })
}
export function GetBuildUnitList (params) {
  return request({
    url: 'Build/GetBuildUnitList',
    method: 'get',
    params
  })
}
// 删除住户信息
export function DeleteResident (data) {
  return request({
    url: 'Resident/DeleteResident',
    method: 'post',
    data
  })
}
// 保存家庭信息
export function SaveBuildFamily (data) {
  return request({
    url: 'Build/SaveBuildFamily',
    method: 'post',
    data
  })
}
//获取家庭
export function GetBuildFamily (params) {
  return request({
    url: 'Build/GetBuildFamily',
    method: 'get',
    params
  })
}
//地图获取房屋列表
export function GetBuildRoomList (params) {
  return request({
    url: 'Build/GetBuildRoomList',
    method: 'get',
    params
  })
}
//根据楼栋id返回楼栋地图数据
export function GetBuildInfo (params) {
  return request({
    url: 'MapData/GetBuildInfo',
    method: 'get',
    params
  })
}
// 保存特困人员供养信息
export function SaveHPovertyStricken (data) {
  return request({
    url: 'Resident/SaveHPovertyStricken',
    method: 'post',
    data
  })
}
// 保存低保人员信息
export function SaveHLower (data) {
  return request({
    url: 'Resident/SaveHLower',
    method: 'post',
    data
  })
}
// 保存廉租房人员信息
export function SaveHLowRent (data) {
  return request({
    url: 'Resident/SaveHLowRent',
    method: 'post',
    data
  })
}
// 保存特抚人员信息
export function SaveHSpecialHelper (data) {
  return request({
    url: 'Resident/SaveHSpecialHelper',
    method: 'post',
    data
  })
}
// 保存党员信息
export function SaveHPartMember (data) {
  return request({
    url: 'Resident/SaveHPartMember',
    method: 'post',
    data
  })
}
// 保存残疾人信息
export function SaveHDisabled (data) {
  return request({
    url: 'Resident/SaveHDisabled',
    method: 'post',
    data
  })
}
// 保存退役军人信息
export function SaveHVeterans (data) {
  return request({
    url: 'Resident/SaveHVeterans',
    method: 'post',
    data
  })
}
// 保存在校生信息
export function SaveHStudent (data) {
  return request({
    url: 'Resident/SaveHStudent',
    method: 'post',
    data
  })
}
// 保存信访人员信息
export function SaveHLetterVisits (data) {
  return request({
    url: 'Resident/SaveHLetterVisits',
    method: 'post',
    data
  })
}
// 保存矫正人员信息
export function SaveHCorrect (data) {
  return request({
    url: 'Resident/SaveHCorrect',
    method: 'post',
    data
  })
}
// 保存刑满释放人员信息
export function SaveHRelease (data) {
  return request({
    url: 'Resident/SaveHRelease',
    method: 'post',
    data
  })
}
// 保存优抚人员信息
export function SaveHPriorHelper (data) {
  return request({
    url: 'Resident/SaveHPriorHelper',
    method: 'post',
    data
  })
}
// 保存吸毒人员信息
export function SaveHDruger (data) {
  return request({
    url: 'Resident/SaveHDruger',
    method: 'post',
    data
  })
}
// 保存邪教人员信息
export function SaveHCults (data) {
  return request({
    url: 'Resident/SaveHCults',
    method: 'post',
    data
  })
}
//商铺信息列表
export function GetShopPage (params) {
  return request({
    url: 'Shop/GetShopPage',
    method: 'get',
    params
  })
}
//根据房号获取商铺信息列表
export function GetShopByRridList (params) {
  return request({
    url: 'Shop/GetShopByRridList',
    method: 'get',
    params
  })
}
// 房屋与商铺信息绑定
export function RoomBindShop (data) {
  return request({
    url: 'Shop/RoomBindShop',
    method: 'post',
    data
  })
}
//楼层列表
export function WeGetBuildFloorList (params) {
  return request({
    url: 'Build/WeGetBuildFloorList',
    method: 'get',
    params
  })
}

//房间号列表
export function WeGetBuildRoomList (params) {
  return request({
    url: 'Build/WeGetBuildRoomList',
    method: 'get',
    params
  })
}

//疫苗接种排查分页列表
export function GetCheckPage (params) {
  return request({
    url: 'Vaccine/GetCheckPage',
    method: 'get',
    params
  })
}
//返洛分页列表
export function GetReportingPage (params) {
  return request({
    url: 'Vaccine/GetReportingPage',
    method: 'get',
    params
  })
}

//离洛分页列表
export function GetOutReportingPage (params) {
  return request({
    url: 'Vaccine/GetOutReportingPage',
    method: 'get',
    params
  })
}

//隔离人员分页列表
export function GetIsolationPage (params) {
  return request({
    url: 'Isolation/GetIsolationPage',
    method: 'get',
    params
  })
}

// 确认返洛人员
export function ConfirmReporting (data) {
  return request({
    url: 'Vaccine/ConfirmReporting',
    method: 'post',
    data
  })
}

// 添加管控人员
export function AddIsolation (data) {
  return request({
    url: 'Isolation/AddIsolation',
    method: 'post',
    data
  })
}
//获取返淇人员报备详情
export function GetReporting (params) {
  return request({
    url: 'Vaccine/GetReporting',
    method: 'get',
    params
  })
}
//获取返淇人员报备详情
export function WxGetReporting (params) {
  return request({
    url: 'Vaccine/WxGetReporting',
    method: 'get',
    params
  })
}

//获取我的巡访区域关系信息
export function WeGetPatrolareaPage (params) {
  return request({
    url: 'Patrolarea/WeGetPatrolareaPage',
    method: 'get',
    params
  })
}